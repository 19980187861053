table.table thead th {
  vertical-align: top;
}

table.table thead th.th-sort {
  cursor: pointer;
  user-select: none;
}

table.table thead .sort {
  display: inline-block;
  height: 15px;
  vertical-align: -2px;
}

table.table thead .sort-selected {
  fill: #00bc8c;
}

table.table thead .sort-unselected {
  fill: #777;
}
