h1 {
  font-size: 18.75px;
}

h2 {
  font-size: 15px;
}

.centered-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
}

/* https://github.com/twbs/bootstrap/issues/27493 */
.table tfoot tr:first-child th {
  border-top-width: 2px;
}

.table {
  white-space: nowrap;
}
