.reset-password-container {
  width: 100%;
  max-width: 330px;
  min-height: 300px;
  padding: 15px;
  margin: auto;
}
.reset-password-container .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.reset-password-container .form-control:focus {
  z-index: 2;
}
.reset-password-container input.reset-password-first {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.reset-password-container input.reset-password-second {
  margin-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.reset-password-container button {
  margin-top: 10px;
}
